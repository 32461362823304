import { AppState } from '@/store/state';
import { UiState } from '@/store/ui/state';
import { Component, HostBinding, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppService } from '@services/app.service';
import { Observable } from 'rxjs';

const BASE_CLASSES = 'main-sidebar elevation-4';
@Component({
    selector: 'app-menu-sidebar',
    templateUrl: './menu-sidebar.component.html',
    styleUrls: ['./menu-sidebar.component.scss']
})
export class MenuSidebarComponent implements OnInit {
    @HostBinding('class') classes: string = BASE_CLASSES;
    public ui: Observable<UiState>;
    public user;
    public menu = MENU;

    constructor(
        public appService: AppService,
        private store: Store<AppState>
    ) { }

    ngOnInit() {
        this.ui = this.store.select('ui');
        this.ui.subscribe((state: UiState) => {
            this.classes = `${BASE_CLASSES} ${state.sidebarSkin}`;
        });
    }
}

export const MENU = [
    {
        name: 'Dashboard',
        iconClasses: 'fas fa-tachometer-alt',
        path: ['/dashboard']
    },
    {
        name: 'Patients Management',
        iconClasses: 'far fa-address-book',
        path: ['/patient']
    },
    {
        name: 'Doctors Management',
        iconClasses: 'far fa-address-book',
        path: ['/doctor']
    },
    // {
    //     name: 'Insurance Management',
    //     iconClasses: 'fas fa-folder',
    //     path: ['/insurance']
    // },
    {
        name: 'Content Management',
        iconClasses: 'fas fa-folder',
        path: ['/content']
    },
    {
        name: 'Faq Management',
        iconClasses: 'fas fa-folder',
        path: ['/faq']
    },
    {
        name: 'Speciality Management',
        iconClasses: 'fas fa-folder',
        path: ['/speciality']
    },
    {
        name: 'Testimonial Management',
        iconClasses: 'fas fa-folder',
        path: ['/testimonial']
    },
    {
        name: 'Locations Management',
        iconClasses: 'fas fa-folder',
        path: ['/locations']
    },
    {
        name: 'Patient Financing',
        iconClasses: 'fas fa-folder',
        path: ['/financing']
    },
    {
        name: 'Appointment List',
        iconClasses: 'fas fa-folder',
        path: ['/appointment']
    },
    {
        name: 'Post Treatment List',
        iconClasses: 'fas fa-folder',
        path: ['/post-treatment']
    },
    {
        name: 'Query',
        iconClasses: 'fas fa-folder',
        path: ['/query']
    },
    {
        name: 'Subscription',
        iconClasses: 'fas fa-folder',
        path: ['/subscription']
    },
    {
        name: 'Notification',
        iconClasses: 'fas fa-folder',
        path: ['/notification']
    },
    {
      name: 'Blog Category',
      iconClasses: 'fas fa-folder',
      path: ['/blog-category']
    },
    {
      name: 'Blog',
      iconClasses: 'fas fa-folder',
      path: ['/blog']
    }
];
