<img class="pangi_logo" src="assets/img/logo.png"  [routerLink]="['/']"  alt="">
<div class="card card-outline card-primary">
    <div class="card-body">
        <p class="login-box-msg">
            You are only one step a way from your new password, recover your
            password now.
        </p>
        <form [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword()">
            <div class="mb-3">
                <div class="input-group">
                    <input formControlName="password" type="text" class="form-control" placeholder="Password"
                        autocomplete="off" />
                    <div class="input-group-append">
                        <div class="input-group-text">
                            <span class="fas fa-lock"></span>
                        </div>
                    </div>
                </div>

                <ng-container *ngIf="
                resetPasswordForm.controls['password'].invalid &&
                (resetPasswordForm.controls['password'].dirty ||
                  resetPasswordForm.controls['password'].touched)
              ">
                    <div *ngIf="resetPasswordForm.controls['password'].errors?.['required']">
                        <p class="text-danger">New Password is required</p>
                    </div>
                </ng-container>
            </div>
            <div class="mb-3">
                <div class="input-group">
                    <input formControlName="confirm_password" type="text" class="form-control"
                        placeholder="Confirm Password" autocomplete="off" />
                    <div class="input-group-append">
                        <div class="input-group-text">
                            <span class="fas fa-lock"></span>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="
                resetPasswordForm.controls['confirm_password'].invalid &&
                (resetPasswordForm.controls['confirm_password'].dirty ||
                  resetPasswordForm.controls['confirm_password'].touched)
              ">
                    <div *ngIf="resetPasswordForm.controls['confirm_password'].errors?.['required']">
                        <p class="text-danger">Confirm Password is required</p>
                    </div>
                    <div *ngIf="resetPasswordForm.controls['confirm_password'].errors?.['MustMatch']">
                        <p class="text-danger">New and Confirm password didn't match </p>
                    </div>
                </ng-container>
            </div>
            <div class="row">
                <div class="col-12">
                    <button style="width: 100%;" [type]="'submit'" pButton pRipple type="button" label="Change password" class="p-button-raised"></button>
                    <!-- <pf-button [type]="'submit'" [block]="true">
                        Change password
                    </pf-button> -->
                </div>
            </div>
        </form>

        <p class="mt-3 mb-1">
            <label class="login" [routerLink]="['/login']">Login</label>
        </p>
    </div>
</div>