<ngx-spinner></ngx-spinner>
<div class="content-header">
    <div class="container-fluid">
        <div style="padding: 10px;" class="row mb-2">
            <div class="col-sm-6">
                <h1 class="m-0 text-dark">Dashboard</h1>
            </div>
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="/dashboard">Home</a></li>
                    <li class="breadcrumb-item active">Dashboard</li>
                </ol>
            </div>
        </div>
    </div>
</div>

<section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-3 col-6">
                <div class="small-box" style="background-color: #36a2eb;">
                    <div class="inner">
                        <h3>{{doctorsCount}}</h3>
                            <p>Doctor </p>
                    </div>
                    <div class="icon">
                        <i class="fas fa-users"></i>
                    </div>
                    <a  class="small-box-footer">
                        More info <i class="fas fa-arrow-circle-right" href="/dotors"></i>
                    </a>
                </div>
            </div>
            <div class="col-lg-3 col-6">
                <div class="small-box" style="background-color: #ff4069;">
                    <div class="inner">
                        <h3>{{patientsCount}}</h3>

                        <p>Patient </p>
                    </div>
                    <div class="icon">
                        <i class="fas fa-users"></i>
                        <!-- <i class="ion ion-person-add"></i> -->
                    </div>
                    <a  class="small-box-footer">
                        More info <i class="fas fa-arrow-circle-right"></i>
                    </a>
                </div>
            </div>
            <div class="col-lg-3 col-6">
                <div class="small-box" style="background-color: #ff9f40;">
                    <div class="inner">
                        <h3>{{appointmentsCount}}</h3>

                        <p>Appointment </p>
                    </div>
                    <div class="icon">
                        <i class="ion ion-person-add"></i>
                    </div>
                    <a  class="small-box-footer">
                        More info <i class="fas fa-arrow-circle-right"></i>
                    </a>
                </div>
            </div>
            <div class="col-lg-3 col-6" >
                <div class="small-box" style="background-color: #ffcd56;">
                    <div class="inner">
                        <h3>{{approvedAppointmentCount}}</h3>

                        <p>Approved Appointment </p>
                    </div>
                    <div class="icon">
                        <!-- <i class="ion ion-stats-bars"></i> -->
                        <i class="ion ion-pie-graph"></i>
                    </div>
                    <a  class="small-box-footer">
                        More info <i class="fas fa-arrow-circle-right"></i>
                    </a>
                </div>
            </div>
            <div class="col-lg-3 col-6">
                <div class="small-box" style="background-color: #4bc0c0;">
                    <div class="inner">
                        <h3>{{pendingAppointmentCount}}</h3>

                        <p>Pending Appointment </p>
                    </div>
                    <div class="icon">
                        <i class="ion ion-pie-graph"></i>
                    </div>
                    <a  class="small-box-footer">
                        More info <i class="fas fa-arrow-circle-right"></i>
                    </a>
                </div>
            </div>
            <div class="col-lg-3 col-6">
                <div class="small-box" style="background-color: #9966ff;">
                    <div class="inner">
                        <h3>{{rejectAppointmentCount}}</h3>

                        <p>Reject Appointment </p>
                    </div>
                    <div class="icon">
                        <i class="ion ion-pie-graph"></i>
                    </div>
                    <a  class="small-box-footer">
                        More info <i class="fas fa-arrow-circle-right"></i>
                    </a>
                </div>
            </div>
            <div class="col-lg-3 col-6">
                <div class="small-box" style="background-color: #c9cbcf;">
                    <div class="inner">
                        <h3>{{todayAppointmenList.length}}</h3>

                        <p>Today's Appointment </p>
                    </div>
                    <div class="icon">
                        <i class="ion ion-person-add"></i>
                    </div>
                    <a  class="small-box-footer" (click)="openTodayAppointmentList()" style="cursor: pointer;">More info <i class="fas fa-arrow-circle-right"></i>
                    </a>
                </div>
            </div>
        </div>
        <p-chart class="mychart" type="pie" [data]="completeData" height="300px"></p-chart>
    </div>
</section>


<p-dialog class="todayAppointmentContainer"
    [(visible)]="openSubscriptionDoneDialog" [modal]="true" [baseZIndex]="10000" [draggable]="true" [resizable]="false"
    [blockScroll]="true">


    <div class="main_table">
        <div class="mytable">
            <p-table #dt1 [value]="todayAppointmenList">
                <ng-template pTemplate="colgroup" let-columns>
                    <colgroup>
                        <col style="width:200px">
                        <col style="width:200px">
                        <col style="width:150px">
                        <col style="width:150px">
                        <col style="width:150px">
                        <col style="width:150px">
                        <!-- <col style="width:250px"> -->
                    </colgroup>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th>
                            <div class="table_name_field">
                                Patient name
                            </div>
                        </th>
                        <th>
                            <div class="table_name_field">
                                Doctor name
                            </div>
                        </th>
                        <th>
                            Phone
                        </th>
                        <th>
                            Gender
                        </th>
                        <th>
                            Slot Timing
                        </th>
                        <th>
                            Visit Reason
                           
                        </th>
                        <!-- <th>
                            Clinic Address
                        </th> -->
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-customer>
                    <tr>
                        <td class="text_format">
                            <div class="table_name_field_data">
                                <span class="patient_name">
                                    {{customer?.patient[0].first_name}} {{customer?.patient[0].last_name}}
                                </span>
                            </div>
                        </td>
                        <td>
                            {{customer?.doctor[0].first_name}}   {{customer?.doctor[0].last_name}}
                        </td>
                        <td>
                            {{customer?.patient[0].phone ? customer.phone : '-'}}
                        </td>
                        <td>
                            {{customer?.patient[0].gender}}
                        </td>
                        <td>
                            {{customer.slot_time}}
                        </td>
                        <td>
                            {{customer.visit_reason}}
                        </td>
                        <!-- <td>
                            {{customer.clinic_address_list}}
                        </td> -->
                   
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="6">No Record found.</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</p-dialog>