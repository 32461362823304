import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../environments/environment';

@Pipe({
  name: 'imageurl'
})
export class ImageurlPipe implements PipeTransform {
  nvalue: string
  imageBasePath = environment.imagePathLocal;
  transform(value: any, ...args: unknown[]): any {
    let imagePath: any;

    if (value != undefined && !value?.type) {
      imagePath = value.split("/");
      if (imagePath[0] == 'public') {
        this.nvalue = this.imageBasePath + value
      } else {
        this.nvalue = value
      }
      return this.nvalue;
    } else {
      return this.nvalue = value
    }
  }
}
