<!-- Brand Logo -->
<a [routerLink]="['/dashboard']" class="brand-link">
    <span class="brand-text" style="font-size: 600; margin-left: 20px;">
    <img src="assets/img/admin-icon.png" alt=""></span>
</a>

<!-- Sidebar -->
<div class="sidebar">
    <!-- Sidebar user (optional) -->
    <!-- <div class="user-panel mt-3 pb-3 mb-3 d-flex">
        <div class="image">
            <pf-image
                [src]="user.picture"
                fallback-src="assets/img/default-profile.png"
                class="img-circle"
                alt="User Image"
                width="34"
                height="34"
                rounded
            ></pf-image>
        </div>
        <div class="info">
            <a [routerLink]="['/profile']" class="d-block">
                {{ user.email }}
            </a>
        </div>
    </div> -->

    <div class="form-inline mt-2">
        <app-sidebar-search></app-sidebar-search>
    </div>

    <!-- Sidebar Menu -->
    <nav class="mt-2" style="overflow:hidden">
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
            <app-menu-item *ngFor="let item of menu" [menuItem]="item"></app-menu-item>
        </ul>
    </nav>
</div>