<ngx-spinner></ngx-spinner>
<img class="pangi_logo" src="assets/img/logo.png"  [routerLink]="['/']"  alt="">
<div class="card card-outline card-primary">
    <!-- <div class="card-header text-center">
        <a [routerLink]="['/']" class="h1"><b>Pangi</b></a>
    </div> -->
    <div class="card-body">
        <p class="login-box-msg">
            You forgot your password? Here you can easily retrieve a new
            password.
        </p>
        <form [formGroup]="forgotPasswordForm" (ngSubmit)="forgotPassword()">
            <div class="mb-3">
                <div class="input-group">
                    <input formControlName="email" type="email" class="form-control" placeholder="Email" />
                    <div class="input-group-append">
                        <div class="input-group-text">
                            <span class="fas fa-envelope"></span>
                        </div>
                    </div>
                </div>

                <ng-container *ngIf="
                forgotPasswordForm.controls['email'].invalid &&
                (forgotPasswordForm.controls['email'].dirty ||
                  forgotPasswordForm.controls['email'].touched)
              ">
                    <div *ngIf="forgotPasswordForm.controls['email'].errors?.['required']">
                        <p class="text-danger">Email is required</p>
                    </div>
                    <div *ngIf="forgotPasswordForm.controls['email'].errors?.['pattern']?.requiredPattern">
                        <p class="text-danger">Please enter valid email.</p>
                    </div>
                </ng-container>
            </div>
            <div class="row">
                <div class="col-12">
                    <button style="width: 100%;" [type]="'submit'" pButton pRipple type="button" label="Request new password" class="p-button-raised"></button>
                    <!-- <pf-button [type]="'submit'" [block]="true">
                        Request new password
                    </pf-button> -->
                </div>
            </div>
        </form>
        <p class="mt-3 mb-1">
            <label class="login" [routerLink]="['/login']">Login</label>
        </p>
    </div>
</div>