<ngx-spinner></ngx-spinner>
<img class="pangi_logo" src="assets/img/logo.png"  [routerLink]="['/']"  alt="">

<div class="card card-outline card-primary">
    <div class="card-body">
        <p class="login-box-msg">Sign in to start your session</p>

        <form [formGroup]="loginForm" (ngSubmit)="login()">
            <div class="mb-3">
                <div class="input-group">
                    <input formControlName="email" type="email" class="form-control" placeholder="Email" />
                    <div class="input-group-append">
                        <div class="input-group-text">
                            <span class="fas fa-envelope"></span>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="
                loginForm.controls['email'].invalid &&
                (loginForm.controls['email'].dirty ||
                  loginForm.controls['email'].touched)
              ">
                    <div *ngIf="loginForm.controls['email'].errors?.['required']">
                        <small class="text-danger">Email is required</small>
                    </div>
                </ng-container>
            </div>
            <div class="input-group mb-3">
                <div class="input-group">
                    <input formControlName="password" type="password" class="form-control" placeholder="Password" />
                    <div class="input-group-append">
                        <div class="input-group-text">
                            <span class="fas fa-lock"></span>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="
                loginForm.controls['password'].invalid &&
                (loginForm.controls['password'].dirty ||
                  loginForm.controls['password'].touched)
              ">
                    <div *ngIf="loginForm.controls['password'].errors?.['required']">
                        <small class="text-danger">Password is required</small>
                    </div>
                </ng-container>
            </div>
            <div class="row">
                <div class="col-12">
                    <button style="width: 100%;" [type]="'submit'" pButton pRipple type="button" label="Sign In" class="p-button-raised"></button>
                    <!-- <pf-button [type]="'submit'" [block]="true" [loading]="isAuthLoading"
                        [disabled]="isFacebookLoading || isGoogleLoading">
                        Sign In
                    </pf-button> -->
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-12">
                    <label class="forgot_password" for="remember" routerLink="/forgot-password"> Forgot Password ?
                    </label>
                </div>
            </div>
        </form>

        <div class="social-auth-links text-center mb-3">
            <!-- <pf-button
                class="mb-2"
                [block]="true"
                [loading]="isFacebookLoading"
                [disabled]="isAuthLoading || isGoogleLoading"
                (click)="loginByFacebook()"
            >
                <i class="fab fa-facebook mr-2"></i>
                Sign in using Facebook
            </pf-button> -->
            <!-- <pf-button
                [block]="true"
                [loading]="isGoogleLoading"
                [disabled]="isAuthLoading || isFacebookLoading"
                theme="danger"
                (click)="loginByGoogle()"
            >
                <i class="fab fa-google mr-2"></i>
                Sign in using Google+
            </pf-button> -->
        </div>

        <!-- <p class="mb-1">
            <a [routerLink]="['/forgot-password']">I forgot my password</a>
        </p> -->
        <!-- <p class="mb-0">
            <a [routerLink]="['/register']" class="text-center">
                Register a new membership
            </a>
        </p> -->

        <!-- <div>
            <p-checkbox name="groupname" value="val1" [(ngModel)]="selectedValues"></p-checkbox>
    
            <p-dropdown [options]="cities" [(ngModel)]="selectedCity" optionLabel="name"></p-dropdown>
        </div> -->
    </div>

</div>