import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from '@modules/main/main.component';
import { LoginComponent } from '@modules/login/login.component';
import { ProfileComponent } from '@pages/profile/profile.component';
import { RegisterComponent } from '@modules/register/register.component';
import { DashboardComponent } from '@pages/dashboard/dashboard.component';
import { AuthGuard } from '@guards/auth.guard';
import { NonAuthGuard } from '@guards/non-auth.guard';
import { ForgotPasswordComponent } from '@modules/forgot-password/forgot-password.component';
import { RecoverPasswordComponent } from '@modules/recover-password/recover-password.component';

const routes: Routes = [
    {
        path: '',
        component: MainComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            {
                path: 'dashboard',
                component: DashboardComponent
            },
            {
                path: 'profile',
                component: ProfileComponent
            },
            {
                path: 'patient',
                loadChildren: () =>
                    import('./pages/patient/patient.module').then(
                        (m) => m.PatientModule
                    )
            },
            {
                path: 'doctor',
                loadChildren: () =>
                    import('./pages/doctor/doctor.module').then(
                        (m) => m.DoctorModule
                    )
            },
            {
                path: 'content',
                loadChildren: () =>
                    import('./pages/content/content.module').then(
                        (m) => m.ContentModule
                    )
            },
            // {
            //     path: 'insurance',
            //     loadChildren: () =>
            //         import('./pages/insurance/insurance.module').then(
            //             (m) => m.InsuranceModule
            //         )
            // },
            {
                path: 'faq',
                loadChildren: () =>
                    import('./pages/faq/faq.module').then((m) => m.FaqModule)
            },
            {
                path: 'speciality',
                loadChildren: () =>
                    import('./pages/speciality/spaciality.module').then((m) => m.SpacialityModule)
            },
            {
                path: 'locations',
                loadChildren: () =>
                    import('./pages/locations/locations.module').then((m) => m.LocationsModule)
            },
            {
                path: 'testimonial',
                loadChildren: () =>
                    import('./pages/testimonial/testimonial.module').then((m) => m.TestimonialModule)
            },
            {
                path: 'financing',
                loadChildren: () =>
                    import('./pages/financing/financing.module').then((m) => m.FinancingModule)
            },
            {
                path: 'query',
                loadChildren: () =>
                    import('./pages/query/query.module').then((m) => m.QueryModule)
            },
            {
                path: 'appointment',
                loadChildren: () =>
                    import('./pages/appointment/appointment.module').then((m) => m.AppointmentModule)
            },
            {
                path: 'post-treatment',
                loadChildren: () =>
                    import('./pages/post-treatment/post-treatment.module').then((m) => m.PostTreatmentModule)
            },
            {
                path: 'subscription',
                loadChildren: () =>
                    import('./pages/subscription/subscription.module').then((m) => m.SubscriptionModule)
            },
            {
                path: 'change-password',
                loadChildren: () =>
                    import('./pages/change-password/change-password.module').then((m) => m.ChangePasswordModule)
            },
            {
                path: 'notification',
                loadChildren: () =>
                    import('./pages/notification/notification.module').then((m) => m.NotificationModule)
            },
            {
              path: 'blog-category',
              loadChildren: () =>
                  import('./pages/blog-category/blog-category.module').then((m) => m.BlogCategoryModule)
            },
            {
              path: 'blog',
              loadChildren: () =>
                  import('./pages/blog/blog.module').then((m) => m.BlogModule)
            },
            {
              path: 'chat',
              loadChildren: () =>
                  import('./pages/chat/chat.module').then((m) => m.ChatModule)
            }
        ]
    },
    {
        path: 'login',
        component: LoginComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'register',
        component: RegisterComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'reset-password/:token',
        component: RecoverPasswordComponent,
        canActivate: [NonAuthGuard]
    },
    { path: '**', redirectTo: '' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        initialNavigation: 'enabledBlocking',
        paramsInheritanceStrategy: 'always',
        useHash: true,
    }),],
    exports: [RouterModule]
})
export class AppRoutingModule { }
