<ul class="navbar-nav">
    <li class="nav-item">
        <a class="nav-link" (click)="onToggleMenuSidebar()" role="button"><i class="fas fa-bars"></i></a>
    </li>
    <!-- <li class="nav-item d-none d-sm-inline-block">
        <a [routerLink]="['/']" class="nav-link">Home</a>
    </li>
    <li class="nav-item d-none d-sm-inline-block">
        <a [routerLink]="['/blank']" class="nav-link">Contact</a>
    </li> -->
</ul>

<form class="form-inline ml-3" [formGroup]="searchForm" *ngIf="false">
    <div class="input-group input-group-sm">
        <input class="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search" />
        <div class="input-group-append">
            <button class="btn btn-navbar" type="submit">
                <i class="fas fa-search"></i>
            </button>
        </div>
    </div>
</form>

<ul class="navbar-nav ml-auto">
    <!-- <app-messages></app-messages>
    <app-notifications></app-notifications>
    <app-language></app-language> -->
    <div style="margin-top: 10px;">
        <h3 style="margin-bottom: 0px;">
            {{first_name? first_name : ''}} {{last_name? last_name : ''}}
        </h3>
    </div>
    <app-user></app-user>
    <!-- <a (click)="logout()" class="btn btn-default btn-flat float-right">
        Sign out
    </a> -->
    <!-- <li class="nav-item">
        <button class="nav-link" (click)="onToggleControlSidebar()">
            <i class="fas fa-th-large"></i>
        </button>
    </li> -->
</ul>